import React from 'react';

import Photo from '../../images/about/AO-PORTRAIT-FORMAL.jpg';

import Layout from '../../components/layout';
import Container from '../../components/container';

import './style.css';

const AboutPage = () => (
  <Layout>
    <Container>
      <section className="about-page">
        <section className="about-photo">
          <img src={Photo} alt="AO Portrait"></img>
        </section>

        <section className="about-text">
          <article>
            <p>
              Anatoly Ostretsov, a licensed architect in California and Russia,
              has over 30 years of experience in the field of architecture and
              interior design with an expertise in residential architecture,
              especially high-end custom single-family homes. In 1985 Anatoly
              graduated from one of the best architectural schools in the Soviet
              Union (nowadays Samara State University of Architecture) with a
              Bachelor’s degree in Architecture.
            </p>
            <p>
              For over 15 years, he worked as an architect and a Director of
              Planning and Building Department of the city of Nadym in Siberia,
              designed a number of beautiful buildings made of brick and
              concrete and published articles in nationwide architectural
              magazines prior to moving to America with his wife and two sons in
              2001. His design experience in Russia included urban planning and
              design, commercial buildings such as airports, hotels, the palace
              of ceremonies, schools, as well as numerous banks, shops, stores
              and apartment buildings. Once established in California, Anatoly
              quickly excelled in styles and construction methods of wood framed
              homes. Before starting AO Architectural Design, Anatoly worked at
              the International Design Group (Pacific Grove, California) for
              thirteen years where he rose to Senior Associate and Project
              Manager.
            </p>
            <p>
              Anatoly is passionate about creating designs that address the
              needs, wants and desires of his clients. He is extremely detail
              oriented which enables him to solve the construction challenges as
              they materialize and is committed to finding solutions that make
              his clients happy. What he appreciates most about custom
              residential design is the opportunity to establish and maintain
              rewarding client relationships based on open and continued
              collaboration with clients, builders and consultants throughout
              the entire design, governmental approval and construction process.
            </p>
            <p>
              Outside of the office, Anatoly enjoys photography, gardening,
              hiking with his family and friends and exploring all of the
              beautiful landscapes that the Monterey Peninsula and Big Sur area
              have to offer.
            </p>
          </article>

          <article className="award-badge">
            <a
              href="https://www.expertise.com/CA/monterey/architects"
              // target="_blank"
            >
              <img
                width="200"
                height="160"
                src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/ca_monterey_architects_2021_transparent.svg"
                alt="Award for Monterey's Best Architects"
              />
            </a>
          </article>
        </section>
      </section>
    </Container>
  </Layout>
);

export default AboutPage;
